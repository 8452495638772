<template>
  <p style="margin: 0">退货协议PDF生成器 ｜ 由
    <a class="custom-link" href="https://github.com/bananaza" target="_blank">github@bananaza</a> 开发 ｜
    <a class="custom-link" href="https://www.515code.com/about" target="_blank">关于515code</a>｜第三方工具：
    <a class="custom-link" href="https://tools.kalvinbg.cn/convenience/seal" target="_blank">印章制作</a>
  </p>
  <p style="margin: 0">
  </p>
  <p style="margin: 0">使用方法：添加或删除一行，然后点击参数进行修改，最后上传印章图片。目前仅支持生成一页。</p>
  <p style="margin: 0;color:blue;">默认模式：请点击蓝色按钮导出PDF。</p>
  <p style="margin: 0;color:red;">千分位模式：不允许编辑表格，点击蓝色按钮导出PDF，或灰色按钮直接下载文件。</p>
  <!-- 按钮 -->
  <label class="custom-button">
    上传甲方印章
    <input type="file" @change="handleStampUpload(1)" accept="image/*" ref="fileInput1">
  </label>
  <label class="custom-button">
    上传乙方印章
    <input type="file" @change="handleStampUpload(2)" accept="image/*" ref="fileInput2">
  </label>
  <button class="green-button" @click="addRow">添加一行</button>
  <button class="red-button" @click="delRow">删除一行</button>
  <button class="primary-button" @click="changeFormat">千分位开关</button>
  <button class="primary-button" v-print="print">导出PDF(推荐)</button>
  <button class="gray-button" @click="downloadAsPDF">直接下载(BUG)</button>
  <div class="a4-print-page" id="a4-print-page">
    <div id="ticket-number"><span>编号: </span><input v-model="ticketNumber" /></div>
    <!-- 在这里放置你的套打内容 -->
    <div class="print-content">
      <!-- 大标题 -->
      <h1 class="page-title">退货协议</h1>
      <!-- 在表格上方添加三行信息 -->
      <div class="table-info">
        <div class="info-row">
          <div><span>甲方（销售方）: </span><input v-model="partyName" /></div>
          <div><span>协议编码: </span><input v-model="agreementCode" /></div>
        </div>
        <div class="info-row">
          <div><span>乙方（购货方）: </span><input v-model="buyerName" /></div>
          <div><span>客户编码: </span><input v-model="customerCode" /></div>
        </div>
        <div class="info-row">
          <span>甲乙双方本着友好合作、互惠互利的精神，经协商一致，甲方同意接受乙方退回以下货物：</span>
        </div>
      </div>
      <!-- 表格1 -->
      <table class="print-table normal" ref="normal">
        <thead>
          <tr>
            <th :style="{ width: widthList[0] }">货物名称</th>
            <th :style="{ width: widthList[1] }">规格型号</th>
            <th :style="{ width: widthList[2] }">数量</th>
            <th :style="{ width: widthList[3] }">单价（不含税）</th>
            <th :style="{ width: widthList[4] }">退货金额（不含税）</th>
            <th :style="{ width: widthList[5] }">退税金额</th>
            <th :style="{ width: widthList[6] }">退货价税合计</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td :style="{ width: widthList[0] }">
              <textarea ref="textarea" v-model="item.name" @input="adjustTextareaHeight(item.name, $event)"></textarea>
            </td>
            <td :style="{ width: widthList[1] }">
              <textarea ref="textarea" v-model="item.specs" @input="adjustTextareaHeight(item.specs, $event)"></textarea>
            </td>
            <td :style="{ width: widthList[2] }">
              <textarea ref="textarea" v-model.number="item.quantity" type="number"
                @input="adjustTextareaHeight(item.quantity, $event)"></textarea>
            </td>
            <td :style="{ width: widthList[3] }">
              <textarea ref="textarea" v-model.number="formattedUnitPrices[index]" type="number"
                @input="adjustTextareaHeight(item.unitPrice, $event)" @blur="updateUnitPrice($event, index)"></textarea>
            </td>
            <td :style="{ width: widthList[4] }">
              <textarea readonly ref="textarea" v-model.number="formattedReturnAmounts[index]" type="number"
                @input="adjustTextareaHeight(item.returnAmount, $event)"></textarea>
            </td>
            <td :style="{ width: widthList[5] }">
              <textarea ref="textarea" v-model.number="formattedReturnTaxs[index]" type="number"
                @input="adjustTextareaHeight(item.returnTax, $event);" @blur="updateReturnTax($event, index)"></textarea>
            </td>
            <td :style="{ width: widthList[6] }">
              <textarea readonly ref="textarea" v-model.number="formattedTotalAmounts[index]" type="number"
                @input="adjustTextareaHeight(item.totalAmount, $event)"></textarea>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="print-table normal" id="total-table-normal" style="margin-top: -4mm;border-top: none;">
        <thead>
          <tr>
            <th :style="{ width: widthList[0] }">合计</th>
            <th :style="{ width: widthList[1] }"></th>
            <th :style="{ width: widthList[2] }">{{ quantitySum }}</th>
            <th :style="{ width: widthList[3] }"></th>
            <th :style="{ width: widthList[4] }">{{ returnAmountSum.toFixed(2) }}</th>
            <th :style="{ width: widthList[5] }">{{ returnTaxSum.toFixed(2) }}</th>
            <th :style="{ width: widthList[6] }">{{ totalAmountSum.toFixed(2) }}</th>
          </tr>
        </thead>
      </table>
      <!-- 表格2 -->
      <table class="print-table thousand" ref="thousand">
        <thead>
          <tr>
            <th :style="{ width: widthList[0] }">货物名称</th>
            <th :style="{ width: widthList[1] }">规格型号</th>
            <th :style="{ width: widthList[2] }">数量</th>
            <th :style="{ width: widthList[3] }">单价（不含税）</th>
            <th :style="{ width: widthList[4] }">退货金额（不含税）</th>
            <th :style="{ width: widthList[5] }">退税金额</th>
            <th :style="{ width: widthList[6] }">退货价税合计</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td :style="{ width: widthList[0] }">
              <span class="thouSpan">{{ item.name }}</span>
            </td>
            <td :style="{ width: widthList[1] }">
              <span class="thouSpan">{{ item.specs }}</span>
            </td>
            <td :style="{ width: widthList[2] }">
              <span class="thouSpan">{{ item.quantity.toLocaleString('en-US') }}</span>
            </td>
            <td :style="{ width: widthList[3] }">
              <span class="thouSpan">{{ Number(item.unitPrice.toFixed(2)).toLocaleString('en-US', {
                minimumFractionDigits:
                  2, maximumFractionDigits: 2
              }) }}</span>
            </td>
            <td :style="{ width: widthList[4] }">
              <span class="thouSpan">{{ Number((-item.quantity * item.unitPrice).toFixed(2)).toLocaleString('en-US', {
                minimumFractionDigits: 2, maximumFractionDigits: 2
              }) }}</span>
            </td>
            <td :style="{ width: widthList[5] }">
              <span class="thouSpan">{{ Number(item.returnTax.toFixed(2)).toLocaleString('en-US', {
                minimumFractionDigits:
                  2, maximumFractionDigits: 2
              }) }}</span>
            </td>
            <td :style="{ width: widthList[6] }">
              <span class="thouSpan">{{ Number(item.totalAmount.toFixed(2)).toLocaleString('en-US', {
                minimumFractionDigits: 2, maximumFractionDigits: 2
              }) }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="print-table thousand" id="total-table-thousand" style="margin-top: -4mm;border-top: none;">
        <thead>
          <tr>
            <th :style="{ width: widthList[0] }"><span class="thouSpan totalThouSpan">合计</span></th>
            <th :style="{ width: widthList[1] }"><span class="thouSpan totalThouSpan"></span></th>
            <th :style="{ width: widthList[2] }"><span class="thouSpan totalThouSpan">{{ quantitySum }}</span></th>
            <th :style="{ width: widthList[3] }"><span class="thouSpan totalThouSpan"></span></th>
            <th :style="{ width: widthList[4] }"><span class="thouSpan totalThouSpan">{{
              Number(returnAmountSum.toFixed(2)).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }) }}</span></th>
            <th :style="{ width: widthList[5] }"><span class="thouSpan totalThouSpan">{{
              Number(returnTaxSum.toFixed(2)).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }) }}</span></th>
            <th :style="{ width: widthList[6] }"><span class="thouSpan totalThouSpan">{{
              Number(totalAmountSum.toFixed(2)).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }) }}</span></th>
          </tr>
        </thead>
      </table>
      <div class="table-info">
        <div class="info-row" style="text-align: left;line-height: 7mm;">
          <span>甲方对销售的货物进行回收，乙方应开出相应的红字增值税专用发票信息表交于甲方，甲乙双方保证提供的退货协议和附送资料的内容真实、完整、准确，并愿对此承担相应法律责任。(此协议仅用于办理红字增值税专用发票信息表)</span>
        </div>
        <!-- <div class="info-row">
          <span></span>
        </div> -->
        <div class="info-row">
          <div>
            <span>甲方（销售方）: </span><input v-model="partyName" />
            <img id="stamp1" v-if="stamp1" :src="stamp1" alt="甲方印章">
          </div>
          <div>
            <span>乙方（购货方）: </span><input v-model="buyerName" />
            <img id="stamp2" v-if="stamp2" :src="stamp2" alt="乙方印章">
          </div>
        </div>
        <div class="info-row">
          <div><span>日期：</span><input style="margin-left: 16mm;" v-model="partyDate" /></div>
          <div><span style="margin-right: 16mm;">日期：</span><input v-model="buyerDate" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js';
import print from 'vue3-print-nb'

export default {
  directives: {
    print
  },
  name: 'A4PrintPage',
  watch: {
    content() {
      this.$nextTick(() => {
        this.adjustTextareaHeight()
      })
    },
    items: {
      deep: true,
      handler(newItems) {
        newItems.forEach(item => {
          // 智能计算金额
          item.returnAmount = -item.unitPrice * item.quantity;
          item.totalAmount = item.returnAmount + item.returnTax;
        });
      },
    },
  },
  computed: {
    formattedUnitPrices: {
      get() {
        return this.items.map(item => item.unitPrice.toFixed(2));
      },
      set(value) {
        // 将输入的字符串值转换为浮点数，并更新items中的unitPrice
        this.items = value.map(price => ({ unitPrice: parseFloat(price) }));
      },
    },
    formattedReturnAmounts: {
      get() {
        return this.items.map(item => item.returnAmount.toFixed(2));
      },
      set(value) {
        // 将输入的字符串值转换为浮点数，并更新items中的unitPrice
        this.items = value.map(amount => ({ returnAmount: parseFloat(amount) }));
      },
    },
    formattedReturnTaxs: {
      get() {
        return this.items.map(item => item.returnTax.toFixed(2));
      },
      set(value) {
        // 将输入的字符串值转换为浮点数，并更新items中的unitPrice
        this.items = value.map(tax => ({ returnTax: parseFloat(tax) }));
      },
    },
    formattedTotalAmounts: {
      get() {
        return this.items.map(item => item.totalAmount.toFixed(2));
      },
      set(value) {
        // 将输入的字符串值转换为浮点数，并更新items中的unitPrice
        this.items = value.map(amount => ({ totalAmount: parseFloat(amount) }));
      },
    },
    quantitySum() {
      // 使用 reduce 函数计算合计数量
      return this.items.reduce((total, item) => {
        // 尝试将 item.quantity 转换为数值，如果无法转换，将其视为0
        const quantity = parseFloat(item.quantity) || 0;
        // 将 total 与 quantity 相加，并返回结果
        return total + quantity;
      }, 0)
    },
    returnAmountSum() {
      return this.items.reduce((total, item) => total + item.returnAmount, 0);
    },
    returnTaxSum() {
      return this.items.reduce((total, item) => total + item.returnTax, 0);
    },
    totalAmountSum() {
      return this.items.reduce((total, item) => total + item.totalAmount, 0);
    },
  },
  data() {
    // let that = this
    return {
      widthList: ['18mm', '6mm', '4mm', '10mm', '10mm', '10mm', '10mm'],
      ticketNumber: 'BH2023102310086123456',
      partyName: '深圳市爱施德股份有限公司',
      partyDate: '2023/10/19',
      agreementCode: 'BH2023101324543564563',
      buyerName: '香蕉科技有限公司',
      buyerDate: '2023/10/19',
      customerCode: 'GE5839210983487720481',
      state: 'normal',
      stamp1: null,
      stamp2: null,
      items: [
        {
          name: '“移动通信设备”IPHONE 15 PRO MAX手机 A3108',
          specs: 'MU2U3CH/A',
          quantity: 10,
          unitPrice: 9999.00,
          returnAmount: -99990.00,
          returnTax: -0.00,
          totalAmount: -99990.00,
        },
        {
          name: '三星 SAMSUNG Galaxy S23 Ultra',
          specs: 'MTOA3CH/A',
          quantity: 1,
          unitPrice: 5999.00,
          returnAmount: -5999.00,
          returnTax: -300.00,
          totalAmount: -6299.00,
        },
        {
          name: '华为（HUAWEI）旗舰手机 Mate 60 Pro 12GB+512GB 雅丹黑',
          specs: 'MTOA3CH/A',
          quantity: 2,
          unitPrice: 8196.00,
          returnAmount: -16392.00,
          returnTax: -520.00,
          totalAmount: -16192.00,
        },

      ],
      print: {
        id: 'a4-print-page',
        popTitle: '', // 打印配置页上方的标题
        // extraHead: '<div style="margin:5px;font-size:10px">由 github.com/bananaza 开发</div>', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
        preview: false, // 是否启动预览模式，默认是false
        previewTitle: '预览的标题', // 打印预览的标题
        previewPrintBtnLabel: '预览结束，开始打印', // 打印预览的标题下方的按钮文本，点击可进入打印
        zIndex: 20002, // 预览窗口的z-index，默认是20002，最好比默认值更高
        // previewBeforeOpenCallback() { console.log('正在加载预览窗口！'); console.log(that.msg, this) }, // 预览窗口打开之前的callback
        // previewOpenCallback() { console.log('已经加载完预览窗口，预览打开了！') }, // 预览窗口打开时的callback
        // beforeOpenCallback() {
        // }, 
        // 开始打印之前的callback
        openCallback() { console.log('正在通过vue3-print-nb组件导出PDF......') }, // 调用打印时的callback
        // closeCallback() { console.log('关闭了打印工具！') }, // 关闭打印的callback(无法区分确认or取消)
        // clickMounted() { console.log('点击v-print绑定的按钮了！') },
        // url: 'http://localhost:8080/', // 打印指定的URL，确保同源策略相同
        // asyncUrl (reslove) {
        //   setTimeout(() => {
        //     reslove('http://localhost:8080/')
        //   }, 2000)
        // },
        standard: '',
        extarCss: ''
      }
    };
  },
  mounted() {
    // 在页面加载后，根据初始文本内容设置<textarea>的高度
    const textAreas = this.$refs.textarea;
    textAreas.forEach((t) => {
      // 重置滚动区域
      t.style.height = 'auto';
      // 设置新的高度以适应文本内容
      this.$refs.thousand.style.display = 'table';
      t.style.height = '4mm'; // 重置高度
      t.style.height = t.scrollHeight + 'px';
      // 隐藏第二个表格
      this.$refs.thousand.style.display = 'none';
    })
  },
  methods: {
    handleStampUpload(number) {
      const fileInput = this.$refs[`fileInput${number}`];
      console.log(fileInput)
      const file = fileInput.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (number === 1) {
            this.stamp1 = e.target.result;
          } else if (number === 2) {
            this.stamp2 = e.target.result;
          }
        };
        reader.readAsDataURL(file);
      }
    },
    updateUnitPrice(e, index) {
      // 手动触发计算属性的更新
      this.items[index].unitPrice = parseFloat(e.target.value);
    },
    updateReturnTax(e, index) {
      // 手动触发计算属性的更新
      this.items[index].returnTax = parseFloat(e.target.value);
    },
    // 监听 <textarea> 输入，根据内容调整高度
    adjustTextareaHeight(textAreaData, event) {
      const textArea = event.target;
      textArea.style.height = '4mm'; // 重置高度
      textArea.style.height = textArea.scrollHeight + 'px'; // 根据内容调整高度
      textAreaData = textArea.value; // 更新数据
    },
    addRow() {
      this.items.push({
        name: '请输入',
        // 其他列数据...
        specs: '请输入',
        quantity: 0,
        unitPrice: 0.00,
        returnAmount: 0.00,
        returnTax: 0.00,
        totalAmount: 0.00,
      });
    },
    delRow() {
      this.items.pop();
    },
    changeFormat() {
      const form1 = this.$refs.normal;
      const totalNormalTable = document.querySelector('#total-table-normal')
      const form2 = this.$refs.thousand;
      const totalThousandTable = document.querySelector('#total-table-thousand')
      if (this.state === 'normal') {
        form1.style.display = 'none';
        totalNormalTable.style.display = 'none';
        form2.style.display = 'table';
        totalThousandTable.style.display = 'table'
        this.state = 'thousand';
      } else if (this.state === 'thousand') {
        form1.style.display = 'table';
        totalNormalTable.style.display = 'table';
        form2.style.display = 'none';
        totalThousandTable.style.display = 'none';
        this.state = 'normal';
      }
      return;
    },
    downloadAsPDF() {
      const content = document.querySelector('.a4-print-page'); // 获取要转换为PDF的内容
      const pdfOptions = {
        margin: [0, 0, -10, 0], // 页边距
        filename: 'a4.pdf', // PDF文件名
        image: { type: 'jpeg', quality: 0.98 }, // 图像质量
        html2canvas: { scale: 3 }, // html2canvas选项
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }, // 长*宽，jsPDF选项
      };
      html2pdf().set(pdfOptions).from(content).save();
    },
  },
};
</script>
<style>
table {
  table-layout: fixed;
  width: 100%;
  /* 设置表格的宽度，可以根据需要调整 */
}

tr {
  display: table-row;
  /* 行 */
}

td {
  overflow: visible;
  /* 值 */
}

textarea {
  margin-right: 0.5mm;
  font-size: 2.5mm;
  width: 99%;
  border: none;
  resize: none;
  cursor: pointer;
  overflow-y: hidden;
  display: block;
  text-align: center;
}

.a4-print-page {
  /* 页面设置不变 */
  position: relative;
  width: 210mm;
  height: 297mm;
  margin: 0 auto;
  padding: 10mm;
  background-color: white;
  box-shadow: 0 0 1mm rgba(0, 0, 0, 0.1);
}

.print-content {
  /* 套打内容容器的样式不变 */
  padding: 5mm;
}

.page-title {
  /* 大标题的样式 */
  font-size: 6mm;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5mm;
}

.print-table {
  /* 表格的样式 */
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 4mm;
  font-size: 3mm;
}

.print-table th,
.print-table td {
  border: 0.1mm solid #000;
  text-align: center;
}

#total-table-normal th,
#total-table-normal td {
  border-top: none;
}

#total-table-thousand th,
#total-table-thousand td {
  border-top: none;
}

#total-table-thousand {
  display: none;
}

/* 定义打印样式，只包括A4范围内的内容 */
@media print {
  body {
    margin: 0;
    padding: 0;
    width: 210mm;
    height: 297mm;
  }

  .a4-print-page {
    box-shadow: none;
    /* 去除阴影 */
  }
}

.table-info {
  font-size: 3.5mm;
  margin-bottom: 4mm;
}

#ticket-number {
  margin-top: -5mm;
  font-size: 3.5mm;
  position: absolute;
  right: 16mm;
}

#ticket-number>input {
  width: 45mm;
}

.info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4mm;
}

input {
  height: 5mm;
  font-size: 3.5mm;
  border: none;
  width: 60mm;
  border-bottom: 0.1mm solid #e0e0e0;
}

.primary-button {
  background-color: #007BFF;
  /* 背景颜色 */
  color: #fff;
  /* 文本颜色 */
  padding: 5px 5px;
  /* 内边距 */
  border: none;
  /* 去掉边框 */
  border-radius: 5px;
  /* 圆角 */
  cursor: pointer;
  /* 鼠标悬停样式 */
  margin: 8px;
}

.green-button {
  background-color: #52be74;
  /* 背景颜色 */
  color: #fff;
  /* 文本颜色 */
  padding: 5px 5px;
  /* 内边距 */
  border: none;
  /* 去掉边框 */
  border-radius: 5px;
  /* 圆角 */
  cursor: pointer;
  /* 鼠标悬停样式 */
  margin: 8px;
}

.red-button {
  background-color: #d52954;
  /* 背景颜色 */
  color: #fff;
  /* 文本颜色 */
  padding: 5px 5px;
  /* 内边距 */
  border: none;
  /* 去掉边框 */
  border-radius: 5px;
  /* 圆角 */
  cursor: pointer;
  /* 鼠标悬停样式 */
  margin: 8px;
}

.gray-button {
  background-color: #9b9b9b;
  /* 背景颜色 */
  color: #fff;
  /* 文本颜色 */
  padding: 5px 5px;
  /* 内边距 */
  border: none;
  /* 去掉边框 */
  border-radius: 5px;
  /* 圆角 */
  cursor: pointer;
  /* 鼠标悬停样式 */
  margin: 8px;
}

.custom-link {
  color: #007BFF;
  /* 链接文本颜色 */
  text-decoration: none;
  /* 去掉下划线 */
  font-weight: bold;
  /* 文本加粗 */
  transition: color 0.3s;
  /* 颜色变化的过渡效果 */
}

.custom-link:hover {
  color: #0056b3;
  /* 鼠标悬停时的颜色 */
}

.thouSpan {
  display: block;
  height: fit-content;
  font-size: 2.5mm;
  cursor: text;
}

.totalThouSpan {
  font-size: 3mm;
}

#stamp1 {
  display: block;
  position: absolute;
  margin-top: -15mm;
  margin-left: 30mm;
  max-height: 40mm;
  opacity: 0.6;
}

#stamp2 {
  display: block;
  position: absolute;
  max-height: 40mm;
  margin-top: -15mm;
  margin-left: 30mm;
  opacity: 0.6;
}

input[type="file"] {
  display: none;
}

.custom-button {
  font-size: 14px;
  display: inline-block;
  background-color: #3498db;
  color: #fff;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin: 5px;
  text-align: center;
  transition: background-color 0.3s;
}

.custom-button:hover {
  background-color: #2980b9;
}
</style>